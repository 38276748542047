/* font-family: "Jost", sans-serif; */
  /* font-family: "Roboto", sans-serif; */

@tailwind base;
@tailwind components;
@tailwind utilities;





