body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100vw;
}
@import "~react-image-gallery/styles/css/image-gallery.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


/* global */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

section{
  width: 100%;
}
ul{
  list-style: none;
  display: flex;
}
a{
  text-decoration: none;
}
img{
  width: 100%;
}
.btn{
  outline: none;
  border: none;
  padding: 12px 28px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 30px;
  cursor: pointer;
  transition: .3s ease;
}
.btn-gradient{
  background-image: linear-gradient(90deg, #7f43ea, #c027f4);
  color: white;
  border: 2px solid transparent;
}
.btn-gradient:hover{
  border: solid 2px #C426F4;
  background-image: none;
  background-color: transparent;
  color: #C426F4;
}
.btn-border{
  border: 2px solid white;
}
.btn-full{
  width: 100%;
}
.btn-gr-border{
  border: solid 2px transparent !important;
  background: linear-gradient(white, white), linear-gradient(180deg, #6E3DCF, #C426F4) !important;
  border-radius: 30px !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  display: inline-block;
}
.btn-gr-border:hover button{
  background: linear-gradient(180deg, #6E3DCF, #C426F4) !important;
  color: #fff;
}
.btn-gr-border button{
  width: 100%;
}
.plan-gallery img{
  height: 250px;
  object-fit: cover;
}
.plan-gallery svg{
  width: 20px !important;
  height: 40px !important;
}
.plan-gallery .image-gallery-play-button{
  display: none !important;
}
.btn-gr-border button{
  background-color: white;
  color: #6E3DCF; 
}
.row{
  display: flex;
}
.col{
  width: 50%;
}
.col-vertical-center{
  height: 100%;
  display: flex;
  align-items: center;
}

.my-container{
  width: 90%;
  max-width: 1250px;
  margin: 0 auto;
}
.verticle-list{
  flex-direction: column;
}

/* typograpjy */
.nav-link{
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: #1d0025;
}
.hero-heading{
  font-family: "Jost", sans-serif;
  color: #fff;
  font-size: 65px;
  line-height: 70px;
}
.text{
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 27px;
}
.light{
  color: white;
}
.sub-heading{
  font-size: 27px;
  font-family: "Jost", sans-serif;

}
.color-primary{
  color: #932bfa;
}
.heading-primary{
  font-family: "Jost", sans-serif;
  font-size: 45px;
  line-height: 52px;
  color: #1d0025;
}
.accent-heading{
  color: #932bfa;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  font-weight: 500;

}
.gradient-text{
  color: transparent;
  background-image: linear-gradient(180deg, #6E3DCF, #C426F4);
  background-clip: text;
}
.widget-title{
  font-size: 20px;
  color: white;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}
.footer-link{
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff9d;
}
.copyright-list{
  width: 100%;
  justify-content: space-between;
}
.css-6hp17o-MuiList-root-MuiMenu-list{
  flex-direction: column;
}
.MuiSelect-select ul{
  flex-direction: column !important;
}
.Mui-selected{
  background-color: #c027f4 !important;
  color: white !important;
}


/* navigation */
#header-top{
  background-color: #000815;
  padding: 15px 0;
  transition: .5s ease;
}
.bottom-header{
  background-color: #c027f4;
  padding: 10px 0;
}
.nav-box{
  padding: 10px 0;
  transition: .3s ease;
  
}
#header{
  box-shadow: 0 10px 15px #6e3dcf18;
  top: 0;
}
.nav-b-x-inner{
  justify-content: space-between;
  align-items: center;
}
.nav-item{
  margin: 0 20px;
}


/* hero */
#hero-section{
  height: 85vh;
  max-height: 500px;
  background-blend-mode: multiply;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}
.hero-content{
  width: 70%;
}
.hero-content p{
  margin: 25px 0 30px 0 ;
}

#crousel{
  width: 100%;
  height: 600px;
}
.crousel .item{
  width: 100% !important;
}
.locations{
  padding: 20px;
  box-shadow: 0 0 20px 0 #6e3dcf19;
  border-radius: 12px;
  display: flex;
  align-items: center;
}
.location-icon{
  width: 80px;
  height: 80px;
  min-width: 80px;
  background-color: #6E3DCF;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  color: white;
}
.location-icon svg{
  width: 40px !important;
  height: 40px !important;
}
.crousel .item .img{
  width: 100%;
  height: 600px !important;
  object-fit: cover;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, .6);
  background-blend-mode: multiply;
  display: flex;
  justify-content: center;
  align-items: center;
 
}
/* #crousel .list .item::after{
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0 0 0 0;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  z-index: 1;
} */


.crousel .item .content{
  
  top: 50%;
  max-width: 1250px;
  width: 90%;
  left: 50%;
  
  padding-right: 30%;
  z-index: 55
 
}
#crousel .item .content p{
  margin: 25px 0;
}


#crousel .thumbnail .item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
#crousel .thumbnail .item .content{
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}
#crousel .arrow{
  position: absolute;
  top: 80%;
  left: 52px;
  z-index: 5;
  width: 100px;
  max-width: 30%;
  display: flex;
  gap: 10px;
  align-items: center;
}
#crousel .arrow button{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #eee4;
  border: none;
  color: white;
  transition: .5s;
  cursor: pointer;
}
#crousel .arrow button:hover{
  background-color: #6E3DCF;
}

#crousel .list .item:nth-child(1){

  z-index: 1;
}
.crousel .list .item:nth-child(1) h1,
.crousel .list .item:nth-child(1) p,
.crousel .list .item:nth-child(1) a{
  transform: translateY(50px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent 0.5s 1s linear 1 forwards;
}
#crousel .list .item:nth-child(1) p{
  animation-delay: 1.8s;
}
#crousel .list .item:nth-child(1) a{
  animation-delay: 2s;
}
.crousel.next .list .item:nth-child(1) .img{
  width: 180px;
  height: 100px;
  position: absolute;
  left: 50%;
  bottom: 50px;
  border-radius: 8px;
  background-color: transparent;
  animation: showImage .5s linear 1 forwards;
}
.crousel.next .thumbnail .item:nth-last-child(1){
  width: 0;
  overflow: hidden;
  animation: showThumbnail .5s linear 1 forwards;
}
.crousel.next .thumbnail{
  transform: translateX(180px);
  animation: transformThumbnail .5s linear 1 forwards;
}
.crousel.prev .list .item:nth-child(2){
  z-index: 2;
}
.crousel.prev .list .item:nth-child(2) .img{
  position: absolute;
  bottom: 0;
  left: 0;
  animation: imageOut .5s linear 1 forwards;
}
.crousel.prev .thumbnail .item:nth-child(1){
  width: 0;
  overflow: hidden;
  opacity: 0;
  animation: showThumbnail .5s linear 1 forwards;
}
.crousel.prev .list .item:nth-child(2) h1,
.crousel.prev .list .item:nth-child(2) p,
.crousel.prev .list .item:nth-child(2) a{
  animation: contentOut 0.5s linear 1 forwards;
}

.crousel.next .arrow button,
.crousel.prev .arrow button{
  pointer-events: none;
}

@keyframes contentOut{
  to{
    transform: translateY(-50px);
    filter: blur(20px);
    opacity: 0;
  }
}

@keyframes imageOut {
  to{
    width: 180px;
    height: 100px;
    border-radius: 8px;
    left: 50%;
    bottom: 50px;
  }
}
@keyframes showContent {
  to{
    opacity: 1;
    filter: blur(0px);
    transform: translateY(0px);
  }
}
@keyframes showImage {
  to{
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
  }
}
@keyframes showThumbnail {
  to {
    width: 180px;
  }
}
@keyframes transformThumbnail{
  to{
    transform: translateX(0);
  }
}

/* category card */
.cat-card-box{
  padding: 20px 20px 30px 20px;
  transition: .5s ease;
}
.cat-card-box:hover{
  transform: translateY(-15px); 
}
.gradient-card{
  border: solid 3px transparent !important;
  background: linear-gradient(white, white), linear-gradient(180deg, #6E3DCF, #C426F4) !important;
  border-radius: 45px !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  box-shadow: 0 10px 15px #6e3dcf18;
}
.gradient-card > div{
  padding: 30px 50px;
}
.cat-card-box img{
  height: 70px;
  width: auto;
  object-fit: contain;
}

/* about */
#about{
  padding: 60px 0;
  background-image: url('./assets/img/ab-shade.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
}
#about .text{
  margin: 20px 0;
}
.abt-col-left{
  padding-right: 50px;
}
#about .row{
  align-items: center;
}

/* addon */
#addon{
  padding: 60px 0;
}
.gradient-card2{
  border: solid 3px transparent !important;
  background: linear-gradient(white, white), linear-gradient(180deg, #6E3DCF, #C426F4) !important;
  border-radius: 40px !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  box-shadow: 0 10px 15px #6e3dcf18;
}
.gradient-card2 > div{
  padding: 20px;
  background: white;
  border-radius: 40px;
}
.addon-box{
  padding: 20px 20px 30px 20px;
  transition: .5s ease;
}
.addon-box img{
  width: 100%;
  border-radius: 25px;
}
.addon-box h2{
  margin-top: 20px;
}

/* how it work */
.how-card{
  min-height: 370px;
  background-color: white;
  border-radius: 40px;
  background-position: center;
  background-size: 103%;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  padding: 30px;
  transition: .5s ease;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .05);
  position: relative;
}
.how-card::before{
  content: '';
  width: 60%;
  position: absolute;
  height: 60%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  left: 50%;
  transform: translateX(-50%) translateY(-230px);
  transition: .5s ease;
}
.theater::before{
  background-image: url('./assets/img/binge-n-fiesta-theater.png');
}
.decoration::before{
  background-image: url('./assets/img/binge-n-fiesta-decoration.png');
}
.ott::before{
  background-image: url('./assets/img/binge-n-fiesta-ott.png');
}
.how-card:hover{
  transform: translateY(-20px);
}
.how-card:hover::before{
  transform: translateX(-50%) translateY(-240px);
}

/* .how-card h2{
  transition-delay: .1s;
} */

#how-work .row{
  justify-content: space-between;
}
#how-work {
  padding: 60px 0;
  background-color: #F9E9FE;
}
/* 
footer */

#footer{
  background-color: #000815;
  padding-top: 90px;
}
#footer .row{
  justify-content: space-between;
}
.footer-nav{
  flex-direction: column;
}
.footer-nav li{
  margin-bottom: 10px;
}
.footer-col{
  width: calc(25% - 60px);
}
.footer-top{
  padding-bottom: 50px;
}
.footer-bottom{
  padding: 20px 0;
  border-top: 2px solid #9f9f9f7e;
}
.footer-bottom li{
  margin: 0 10px;
}
.footer-bottom li:nth-child(1){
  margin-left: 0;
}
.footer-contact{
  display: flex;
}
.footer-contact span{
  margin-left: 10px;
}
.footer-logo{
  margin-bottom: 20px;
}
.callback-form .col{
  margin-bottom: 16px;
}
.callback-form .col:nth-child(odd){
  margin-right: 8px;
  width: calc(50% - 8px) !important;
}
.callback-form .col:nth-child(even){
  margin-left: 8px;
  width: calc(50% - 8px) !important;
}


/* city */
#city{
   padding: 60px 0;
}
.city-row{
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
}
.city-col{
  width: calc(33.33% - 40px);
  border-radius: 25px;
  border: 3px solid #f7f7f7;
  cursor: pointer;
  box-shadow: 0 10px 15px #6e3dcf18;
  transition: .4s ease;
  margin: 20px;
}
.city-col:hover,.my-booking-card:hover{
  border: solid 3px transparent !important;
  background: linear-gradient(white, white), linear-gradient(180deg, #6E3DCF, #C426F4) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
}
.my-booking-left,.my-booking-right{
  width: 30%;
}
.my-booking-mid{
  width: 40%;
  padding: 0 30px;
}
.my-booking-inner{
  padding: 20px;
  display: flex;
}
.my-booking-addon-list{
  flex-direction: column;
}
.my-booking-addon-list li{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
}
.my-booking-right{
  padding-left: 30px;
  border-left: 2px solid #e1e1e1;
}
.my-booking-inner img{
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}
.city-box{
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  
}
.city-box img{
  border-radius: 20px;
}
.city-content{
  margin-top: 20px;
}
.city-content span{
  margin-top: 30px;
}
.login-overlay,.gal-overlay{
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .8);
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
}
.MuiPickersLayout-root{
  z-index: inherit !important;
}
.gallery-box{
  width: 95%;
  max-width: 700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.gallery-box .gallery-item{
  height: 400px;
  border-radius: 20px;
}

.login-content{
  width: 95%;
  max-width: 500px;
  background-color: white;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 40px;
}
.login-content img{
  width: 70px;
  margin: 0 auto;
  display: block;
}

/* addon */
#addon-hero{
  background-image: linear-gradient(90deg, #6E3DCF, #C426F4);
  padding: 60px 0;
}
.location-pi{
  background-color: white;
  padding: 15px 20px;
  margin: 20px 0;
  border-radius: 5px;
  display: inline-block;
}
#addon-hero ul li{
  margin: 5px 0;
}
.addon-hero-img{
  height: 330px;
  width: auto;
  text-align: right;
  border-radius: 20px;
}
.addon-r-col{
  display: flex;
 justify-content: end;
}
.addon-body-l-col{
  width: calc(70% - 20px);
}
#addon-body{
  padding: 60px 0;
}
#addon-body .row{
  justify-content: space-between;
}
.addon-box{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow-x: auto;
 
}
.addon-card{
  width: calc(25% - 40px);
  min-width: calc(25% - 40px);
  border-radius: 25px;
  border: 3px solid #f7f7f7;
  cursor: pointer;
  box-shadow: 0 10px 15px #6e3dcf18;
  transition: .4s ease;
  margin: 20px;
}
.my-booking-card{
  border-radius: 25px;
  border: 3px solid #f7f7f7;
  cursor: pointer;
  box-shadow: 0 10px 15px #6e3dcf18;
  transition: .4s ease;
  margin: 20px;
}

.selectrd-card{
  border: solid 3px transparent !important;
  background: linear-gradient(white, white), linear-gradient(180deg, #6E3DCF, #C426F4) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
}
.addon-card img{
  width: 180px;
  height: 180px;
 
  
}
.addon-card-inner{
  padding: 30px;
}
.addon-body-r-col{
  width: 50%;
  margin: 0 auto;
  position: sticky;
  top: 60px;
}

/* widgets */
.widget{
  background-color: #F9E9FE;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 30px;
}
.widget-2{
  border-bottom: 2px solid #c027f4;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.step-p-section{
  padding: 60px 0;
}


/* review */
.review-text{
  background-color: white;
  padding: 8px 15px;
  border-radius: 5px;
  margin-left: 15px;
}
.review-list li{
  margin: 10px 0;
  display: flex;
  align-items: center;
}
.addon-review{
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #dca7ec;
}
.addon-review-inner{
  display: flex;
  flex-wrap: wrap;
}
.addont-review-tag{
  background-image: linear-gradient(90deg, #7f43ea, #c027f4);
  padding: 8px 15px;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.nav-mobie-overlay,.toggle{
  display: none;
}
/* 
mui */

.MuiFormControl-root{
  box-shadow: 0 0px 25px -10px #6e3dcf23;
}
.Mui-focused {
  color: #c027f4 !important;
}
.Mui-focused fieldset.MuiOutlinedInput-notchedOutline{
  border-color: #c027f4 !important;

}
fieldset{
  border-radius: 0 !important;
  border: none !important;
  background-color: #c127f419;
  border-bottom: 2px solid #c027f4 !important;
  
}
#header{
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9;
}
.MuiButtonBase-root path{
  fill: #c027f4;
}



#slot .col,#review .col{
  width: calc(50% - 20px);
}
#review .row, #slot .row{
  justify-content: space-between;
}

.table-row{
  display: flex;
  border: 1px solid #0008154a;
  align-items: center;
}
.table-row h3{
  font-size: 16px !important;
}
.cell{
  width: 50%;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}
ul.MuiMenu-list{
  display: block !important;
}
.cell-l{
  border-right: 1px solid #0008154a;
}
.term-list{
  margin-bottom: 15px;
  position: relative;
  margin-left: 35px;
}
.term-list::before{
  content: attr(data-list);
  width: 15px;
  height: 25px;
  min-width: 25px;
  background-color: #c027f4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  left: -35px;
  top: 4px;
 
}
.rev-box{
  margin: 0 20px !important;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  
}
.image-gallery-image{
  object-fit: cover !important;
}
.google-rev .slick-slide > div{
  min-height: 100%;
}
.google-rev .slick-slide > div > div{
  min-height: 100% !important;
}
.rev-inner{
  padding: 10px;
  background-color: white;
  width: 100%;
  border-radius: 10px;
 
}
.gallery-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
}
.gallery-item{
  height: 240px;
  overflow: hidden;
}
.slick-prev::before,
.slick-next::before{
  color: #6E3DCF !important;
}
.gallery-item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: .3s ease;
}
.gallery-item:hover img{
  transform: scale(1.3);
}
/* tab */
@media only screen and (max-width: 1024px) {
  .city-col{
    width: calc(50% - 40px);
  }
  .callback-form .row{
   flex-direction: row;
   justify-content: space-between;
   flex-wrap: nowrap;
  }
  .crousel .item .content{
  
    top: 50%;
    max-width: 1250px;
    width: 90%;
    left: 50%;
    
    padding-right: 0%;
    z-index: 55
   
  }
  .callback-form .col:nth-child(odd){
    margin-right: 0px;
    width: calc(50% - 10px) !important;
  }
  .callback-form .col:nth-child(even){
    margin-left: 0px;
    width: calc((50% - 10px)) !important;
  }
  .my-booking-left,.my-booking-right{
    width: 100%;
  }
  .my-booking-mid{
    width: 100%;
    padding: 30px 0px 0 0;
  }
  .addon-box{
    padding: 0;
  }
  .my-booking-inner{
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .my-booking-right{
    padding: 0px;
    margin-top: 30px;
    padding: 30px 0 0 0;
    border: 0;
    border-top: 2px solid #e1e1e1;
  }
  .gallery-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
}

}


/* Mobile */

@media only screen and (max-width: 768px) {
  .toggle{
    display: block;
  }
  .header-btn{
    display: none;
  }
  .nav-desktop{
    display: none !important;
  }
  .nav-mobie-overlay{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000815c6;
    display: block;
    transition: .4s ease;
    z-index: 555555;
  }
  .collapsed{
    left: -100%;
  }
  .nav-mobile-sidebar{
    width: 70%;
    max-width: 400px;
    height: 100vh;
    background-color: white;
    z-index: 55555;
    transition-delay: .2s;
    transition: .4s ease;
  
  }
  .collapsed .nav-mobile-sidebar{
    left: -100%;
  }
  .mobile-nav-header{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f9f9f9;

  }
  .mobile-nav-body{
    padding: 20px;
  }
  .mobile-nav-body ul{
    flex-direction: column;
  }
  .mobile-nav-body ul li{
    margin: 0;
    padding: 8px 0;
  }

  /* global */
  .row{
    flex-direction: column;
  }
  .col{
    width: 100%;
  }
  nav.row{
    flex-direction: row;
  }


  /* typography mobile */
  .nav-link{
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: #1d0025;
  }
  .hero-heading{
    font-size: 45px;
    line-height: 50px;
  }
  .text{
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  .sub-heading{
    font-size: 22px;
  }
  .heading-primary{
    font-size: 35px;
    line-height: 40px;
  }
  .accent-heading{
    font-size: 18px;
  }
  .col{
    width: 100%;
  }
/* 
  hero mobile */

  #hero-section{
    height: 85vh;
  }
  .hero-content{
    width: 100%;
  }
  .hero-content p{
    margin: 25px 0 30px 0 ;
  }
  .cat-card-box{
    padding: 50px 10px;
  }
  .hero-h-box{
    min-height: 280px !important;
    height: 280px;
  }
 

  /* about mobile */
  .abt-col-right{
    margin-top: 30px;
  }
  .abt-col-left{
    padding: 0;
  }
  .how-card{
    width: 100% !important;
    margin-bottom: 200px;
  }
  .how-card:nth-child(3){
    margin-bottom: 0;
  }
  .footer-col{
    width: 100%;
    padding-left: 0 !important;
    padding-bottom: 40px;
  }
  .city-col{
    width: 100%;
    margin: 20px 0;
  }
  .city-title .col{
    display: flex;
    justify-content: center !important;
  }
  .city-title .col:nth-child(2){
    margin-top: 20px;
  }
  .addon-hero-img{
    width: 100%;
    object-fit: cover;
    margin-top: 30px;
  }

  #slot .col,#review .col{
    width: 100%;
  }
  .addon-card{
    width: 100%;
    min-width: calc(25% - 40px);
  }
  .btn-row .btn-gr-border{
    margin: 15px 0;
  }
  .gallery-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
}

.crousel .list .item .content{
  padding: 0 !important;
}
#crousel .arrow{
  top: 90%;
  left: 30px;
}

 
}